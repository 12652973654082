
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'Field',
  props: {
    label: {
      type: String,
      default: () => undefined,
    },
    value: {
      type: [Number, String],
      default: () => undefined,
    },
  },
});
